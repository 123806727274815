import React, { useState, useEffect } from 'react';
import template from '../icons/template.jpg';
import search from '../icons/search.jpg';
import trials from '../icons/trials.jpg';
import extraction from '../icons/extraction.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { useAuthInfo } from '@propelauth/react';
import { sendEmailToAdmin, handleMessages } from '../utils/utils';

function GeneralSearchPage() {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedResource, setSelectedResource] = useState(null);
  const { user } = useAuthInfo();
  const [chatMessages, setChatMessages] = useState([]);
  const [allMessages, setAllMessages] = useState([]);
  const [input, setInput] = useState('');


  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      handleSendMessage(e);
    }
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (input.trim() === '') return;
  
    console.log("input", input);
  
    const newMessage = {
      content: input,
      role: 'user', // or whatever role is appropriate
    };
  
    // Update chatMessages and allMessages with the new message
    const updatedChatMessages = [...chatMessages, newMessage];
    const updatedAllMessages = [...allMessages, newMessage];
  
    setChatMessages(updatedChatMessages);
    setAllMessages(updatedAllMessages);
    setInput(''); // Clear the textarea
  
    // Pass the updated allMessages to the backend
    const allMessagesReturned = await handleMessages(user.email, updatedAllMessages, []);
  
  
    // Get response from backend and return as allMessages
    const messagesToDisplay = [];
    for (let i = 0; i < allMessagesReturned.length; i++) {
      if ((allMessagesReturned[i].role === 'system' && allMessagesReturned[i + 1] && allMessagesReturned[i + 1].role === 'system') || (i === 0)) {
        continue;
      }
      messagesToDisplay.push(allMessagesReturned[i]); // Include the message
    }
    console.log("messagesToDisplay", messagesToDisplay);
    setChatMessages(messagesToDisplay);
    setAllMessages(allMessagesReturned);
  };


  return (
    <div className="HomePage">
      <header className="HomePage-header">
        <h1>General Search</h1>
        <p className='sub-header'>Quickly search high-level, public material related to life sciences R&D with Artos's General Search tool designed to search life sciences R&D information.</p>
      </header>
      <div className="chatbot-container">
        <div className="chatbot-messages">
          {chatMessages.map((message, index) => (
            <div key={index} className={`message ${message.role}`}>
              {message.content}
              {message.role === 'system' && message.sources && (
                <>
                  <div style={{ marginTop: 10, fontWeight: 'bold' }}>Sources:</div>

                  <div className="sources-container">
                    {message.sources.map((source, sourceIndex) => (
                      <a key={sourceIndex} href={source.url} target="_blank" rel="noopener noreferrer" className="source-tile">
                        {source.name}
                      </a>
                    ))}
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
        <form onSubmit={handleSendMessage} className="chatbot-form">
          <textarea
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Type a message..."
            onKeyDown={handleKeyPress}
            className="chatbot-input"
          />
          <button type="submit" className="chatbot-send-button">Send</button>
        </form>
        <p className='sub-text' style={{ paddingTop: 12, paddingLeft: 10, fontSize: 14, backgroundColor: "#f9f9f9" }}>**General Search is designed to provide high-level information and may make mistakes on specific details. Make sure to check the sources!**</p>
      </div>

    </div>
  );
}

export default GeneralSearchPage;