

export const sendEmailToAdmin = async (email,folder) => {
    
  
  
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    const raw = JSON.stringify({
      "email": email,
      "folder": folder
    });
    
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    
    fetch(`${process.env.REACT_APP_API_URL_BASE}/api/send_email_to_admin`, requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
  };



export const handleMessages = async (email, messages, sources) => {

  console.log('passed in messages:', messages);

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  
  const raw = JSON.stringify({
    "email": email,
    "messages": messages,
    "allowed_sources": sources
  });
  
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };
  
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL_BASE}/api/general_search`, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
    throw error; // Re-throw the error to be handled by the caller
  }



}