import React from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import logo from './icons/LOGO.svg';
import './App.css';
import HomePage from './pages/HomePage';
import TemplatesPage from './pages/TemplatesPage';
import GeneralSearchPage from './pages/GeneralSearchPage';
import { withAuthInfo } from '@propelauth/react';
// import dotenv from 'dotenv';
// dotenv.config();

// function App() {
const App = withAuthInfo((props) => {
  return (
    <Router>
      <div className="App">
        <div className="header">
          <div className="header-top">
            <Link to="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
              <div className="logo"></div>
              <img src={logo} style={{ marginRight: '4px' }} alt="Logo" />
              <h2 className="top-header">Artos</h2>
            </Link>
          </div>
        </div>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/templates" component={TemplatesPage} />
          <Route path="/generalsearch" component={GeneralSearchPage} />
        </Switch>
      </div>
    </Router>
  );
});

export default App;