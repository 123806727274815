import React, { useState } from 'react';
import template from '../icons/template.jpg';
import search from '../icons/search.jpg';
import trials from '../icons/trials.jpg';
import extraction from '../icons/extraction.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { useAuthInfo } from '@propelauth/react';
import { sendEmailToAdmin } from '../utils/utils';

function TemplatesPage() {
    const [showPopup, setShowPopup] = useState(false);
    const [selectedResource, setSelectedResource] = useState(null);
    const { user } = useAuthInfo();
  
    const resources = [
    //   { title: 'Module 1 US', description: "Admin Module", path: 'https://www.google.com', image: template },
    //   { title: 'Module 1 CA', description: "Admin Module", path: 'https://www.google.com', image: template },
    //   { title: 'Module 1 EU', description: "Admin Module", path: 'https://www.google.com', image: template },
    { title: 'Module 2', description: "Summary Module", name: 'Module 2 Summaries_artos.zip', image: template },
    { title: 'Module 3, Pt. 1', description: "Quality Module (non-granular, 3.2.A and 3.2.P)", name: 'Module 3 Quality (3.2.A and 3.2.P)_artos.zip', image: search },
    { title: 'Module 3, Pt. 2', description: "Quality Module (non-granular, 3.2.R and 3.2.S)", name: 'Module 3 Quality (3.2.R and 3.2.S)_artos.zip', image: search },
    { title: 'Module 4', description: "Safety Module", name: 'Module 4 Safety_artos.zip', image: trials },
    { title: 'Module 5, Pt. 1', description: "Efficacy Module (Section 16 docs)", name: 'Module 5 Efficacy (Non-Section 16)_artos.zip', image: extraction },
    { title: 'Module 5, Pt. 2', description: "Efficacy Module (Non-Section 16 docs)", name: 'Module 5 Efficacy (Section 16)_artos.zip', image: extraction },
  ];

  
    const handleResourceClick = (resource) => {
      setSelectedResource(resource);
      setShowPopup(true);
    };
  
    const handlePopupResponse = async (response) => {
      if (response === 'yes') {
        try {
          await sendEmailToAdmin(user.email, selectedResource.name);
          alert(`An email with ${selectedResource.title} templates has been sent to your email. If you don't see it within 30 minutes, contact info@artosai.com.`);
        } catch (error) {
          console.error('Error sending email:', error);
          alert('There was an error sending the email. Please try again later.');
        }
      }
      setShowPopup(false);
      setSelectedResource(null);
    };
  
    return (
      <div className="HomePage">
        <header className="HomePage-header">
          <h1>CTD Templates</h1>
          <p className='sub-header'>Access CTD templates that have been used by thousands of companies, brought to you by Artos's team of regulatory and medical writing experts.</p>
        </header>
        <div className="resources-grid">
          {resources.map((resource, index) => (
            <div key={index} className="template-item" onClick={() => handleResourceClick(resource)}>
              <div className="resource-header">
                <FontAwesomeIcon icon={faFileAlt} size="3x" className="resource-icon" />
                <div className="resource-text">
                  <h2 className="resource-title">{resource.title}</h2>
                  <p className="resource-description">{resource.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        {showPopup && (
          <div className="popup">
            <div className="popup-content">
              <p>{selectedResource.title} templates will be emailed to you. Would you like to proceed?</p>
              <button onClick={() => handlePopupResponse('no')}>No</button>
              <button onClick={() => handlePopupResponse('yes')}>Yes</button>
            </div>
          </div>
        )}
      </div>
    );
  }
  
  export default TemplatesPage;