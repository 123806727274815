import React from 'react';
import { Link } from 'react-router-dom';
import template from '../icons/template.jpg';
import search from '../icons/search.jpg';
import trials from '../icons/trials.jpg';
import extraction from '../icons/extraction.jpg';
import { withAuthInfo } from '@propelauth/react'

const HomePage = withAuthInfo((props) => {

    const resources = [
        { title: 'CTD Templates', description: "Access CTD templates that have been used by thousands of companies, brought to you by Artos's team of regulatory and medical writing experts.", path: '/templates', image: template },
        { title: 'General Search', description: "Quickly search high-level, public material related to life sciences R&D with Artos's General Search tool designed to search life sciences R&D information.", path: '/generalsearch', image: search },
        // { title: 'Trial Designer', description: "Get easy side-by-side comparisons of key parameters of clinical trials of interest to ensure trials are designed optimally.", path: 'https://www.google.com', image: trials },
        // { title: 'Data Extractor', description: "Easily extract information from complex documents for chat/summary use cases, multi-modal ingestion, or AI-powered automations.", path: 'https://www.google.com', image: extraction },
    ]

    if (props.isLoggedIn) {
        return (
            <div className="HomePage">
                <header className="HomePage-header">
                    <h1>Welcome to the Artos community!</h1>
                    <p className='sub-header'>Access free templates, tools, and other resources here. Make sure to check back regularly; we're always releasing more resources.</p>
                </header>
                <div className="resources-grid">
                    {resources.map((resource, index) => (
                        <Link to={`${resource.path}`} key={index} className="resource-link">
                            <div className="resource-item">
                                <img src={resource.image} alt={resource.title} className="resource-image" />
                                <h2 className="resource-title">{resource.title}</h2>
                                <p className="resource-description">{resource.description}</p>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        );
    } else {
        return <p>You are not logged in</p>
    }
});

export default HomePage;